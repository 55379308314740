<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BIR ALPHA LIST</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="category_id"
            dense
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="payroll_data"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat >
            <v-toolbar-title>Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.name
              }}
            </td>
            <td>
              {{ item.details.tin_no
              }}
            </td>
            <td>
              {{ item.details.employee_position.position
              }}
            </td>
            <td>
              {{ item.details.status
              }}
            </td>
            <td>
              {{ item.details.date_of_birth
              }}
            </td>
            <td>
              {{ item.details.contact_no
              }}
            </td>
            <td>
              {{ item.details.address
              }}
            </td>
            <td>
              {{ item.details.date_of_employment
              }}
            </td>
            <td>
              {{ item.last_pay
              }}
            </td>
            <td>
              {{ item.compensation_rate
              }}
            </td>
            <td>
              {{ duration_counter(item.details.date_of_employment,item.last_duty) }}
            </td>
            <td>
              {{ item.annual_gross
              }}
            </td>
            <td>
              {{ item.thirtin
              }}
            </td>
            <td>
              {{ item.benefits
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      selection: '',
      status: '',
      year: '',
      year_items: [],
      payroll_data: [],
      duration: '',
      sorted_by: 'Name',
      bank_type: 'All',
      bank_type_items: [],
      position: 'All',
      position_items: [],
      batch_no: '',
      batch_no_items: [],
      category_id: '',
      category_items: [],
      search: '',
      headers: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'TIN', value: 'details.tin_no', sortable: false},
        {text: 'Position', value: 'details.employee_position.position', sortable: false},
        {text: 'Status', value: 'amount', sortable: false},
        {text: 'Date of Birth', value: 'bank_code_name', sortable: false},
        {text: 'Contact #', value: 'bank_code_name', sortable: false},
        {text: 'Residence', value: 'bank_code_name', sortable: false},
        {text: 'Date of Employment', value: 'bank_code_name', sortable: false},
        {text: 'Last Pay', value: 'bank_code_name', sortable: false},
        {text: 'Daily Rate', value: 'bank_code_name', sortable: false},
        {text: 'Duration', value: 'bank_code_name', sortable: false},
        {text: 'Annual Gross', value: 'bank_code_name', sortable: false},
        {text: '13th Month', value: 'bank_code_name', sortable: false},
        {text: 'Benefits', value: 'bank_code_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('category', ['list_of_category_have_thirten_month_pay']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee',]),
      ...mapActions('employee', ['bir_aplha_list',]),
      duration_counter(from,to) {
        var a = moment(to,'YYYY-MM-DD');
        var b = moment(from, 'YYYY-MM-DD');

        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');
        return years + ' years ' + months + ' months ' + days + ' days'
      },
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('year', this.year);
        data.append('category_id', this.category_id);
        this.bir_aplha_list(data)
          .then(response => {
            this.payroll_data=response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
